<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      scrollable
      persistent
      max-width="480px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title>{{ $t("checkpassword.setnewpassword") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list>
            <v-list-item style="align-items:baseline">
              <v-list-item-content>
                <v-list-item-title
                  >{{ $t("checkpassword.newpassword") }} :</v-list-item-title
                >
              </v-list-item-content>
              <div class="pt-0 pl-0">
                <v-text-field
                  v-model="newpassword"
                  outlined
                  dense
                  :type="shownewpassword ? 'text' : 'password'"
                  :append-icon="shownewpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="shownewpassword = !shownewpassword"
                  autocomplete="new-password"
                >
                </v-text-field>
              </div>
            </v-list-item>
            <v-list-item style="align-items:baseline">
              <v-list-item-content>
                <v-list-item-title
                  >{{
                    $t("checkpassword.confirmnewpassword")
                  }}
                  :</v-list-item-title
                >
              </v-list-item-content>
              <div class="pt-0 pl-0">
                <v-text-field
                  v-model="confirmnewpassword"
                  outlined
                  dense
                  :type="showconfirmpasswordnew ? 'text' : 'password'"
                  :append-icon="
                    showconfirmpasswordnew ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    showconfirmpasswordnew = !showconfirmpasswordnew
                  "
                >
                </v-text-field>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('closedialog'), (this.otp = '')"
            color="red"
            text
            dark
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn
            color="primary"
            @click="
              callnewpassword(), (newpassword = ''), (confirmnewpassword = '')
            "
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="show"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("checkpassword.setnewpassword")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="color.theme"
                @click="$emit('closedialog'), (this.otp = '')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText">{{ $t("checkpassword.newpassword") }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="newpassword"
            :type="shownewpassword ? 'text' : 'password'"
            :append-icon="shownewpassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="shownewpassword = !shownewpassword"
            height="35px"
            autocomplete="new-password"
            persistent-hint
          ></v-text-field>
          <span :style="titleText">{{
            $t("checkpassword.confirmnewpassword")
          }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="confirmnewpassword"
            :type="showconfirmpasswordnew ? 'text' : 'password'"
            :append-icon="
              showconfirmpasswordnew ? 'mdi-eye' : 'mdi-eye-off'
            "
            @click:append="
              showconfirmpasswordnew = !showconfirmpasswordnew
            "
            height="35px"
            autocomplete="off"
            persistent-hint
          ></v-text-field>
          <div class="text-center">
            <v-btn
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="45%"
              color="red"
              outlined
              @click="$emit('closedialog'), (this.otp = '')"
              >{{ $t("checkpassword.cancel") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="45%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="
                callnewpassword(), (newpassword = ''), (confirmnewpassword = '')
              "
              >{{ $t("checkpassword.ok") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      newpassword: "",
      confirmnewpassword: "",
      shownewpassword: false,
      showconfirmpasswordnew: false,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
  },
  props: ["show", "filedata"],
  methods: {
    callnewpassword() {
      console.log(this.newpassword);
      console.log(this.confirmnewpassword);
      if (this.newpassword === "" || this.confirmnewpassword === "") {
        Toast.fire({
          icon: "error",
          title: "กรุณาใส่รหัสผ่าน",
        });
      } else {
        if (this.newpassword !== this.confirmnewpassword) {
          Toast.fire({
            icon: "error",
            title: "ยืนยันรหัสผ่านไม่ตรงกัน",
          });
        } else {
          if (this.filedata.file_type === "folder") {
            let payload = {
              folder_id: this.filedata.file_id,
              account_id: this.dataAccountId,
              new_password: this.newpassword,
            };
            console.log("folder", payload);
            this.axios
              .post(
                process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
                  "/api/modify_password/lock_folder",
                payload
              )
              .then((response) => {
                console.log("ressfolder", response);
                if (response.data.status === "OK") {
                  Toast.fire({
                    icon: "success",
                    title: "แก้ไขสำเร็จ",
                  });
                  this.$emit("closedialog");
                  this.newpassword = "";
                  this.confirmnewpassword = "";
                } else {
                  Toast.fire({
                    icon: "error",
                    title: response.data.errorMessage,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              });
          } else {
            let payload = {
              file_id: this.filedata.file_id,
              account_id: this.dataAccountId,
              new_password: this.newpassword,
            };
            console.log("file", payload);
            this.axios
              .post(
                process.env.VUE_APP_SERVICE_UPDATE_FILE +
                  "/api/modify_password/lock_file",
                payload
              )
              .then((response) => {
                console.log("ressfile", response);
                if (response.data.status === "OK") {
                  Toast.fire({
                    icon: "success",
                    title: "แก้ไขสำเร็จ",
                  });
                  this.$emit("closedialog");
                  this.newpassword = "";
                  this.confirmnewpassword = "";
                } else {
                  Toast.fire({
                    icon: "error",
                    title: response.data.errorMessage,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              });
          }
        }
      }
    },
  },
};
</script>
<style>
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>